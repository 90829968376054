import { Button, Errors, Form, Heading, Input, Label, TextField } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { useUser } from '../../providers/user';
import { gql, mutationAction, useMutation } from '../../graphql';

export default function Profile() {
	const user = useUser();
	const changeNameAction = useMutation(mutation);

	return (
		<>
			<Title>Profile | My account | Troon</Title>
			<Heading as="h1">Profile</Heading>
			<Form action={changeNameAction} class="flex flex-col gap-4 rounded border border-neutral p-6">
				<Heading as="h2" size="h3">
					Update Name
				</Heading>
				<div class="flex w-full flex-row flex-wrap gap-4">
					<TextField name="firstName" class="grow">
						<Label>First name</Label>
						<Input value={user()!.me.firstName} />
					</TextField>

					<TextField name="lastName" class="grow">
						<Label>Last name</Label>
						<Input value={user()!.me.lastName} />
					</TextField>
				</div>

				<Errors />

				<Button type="submit" class="w-fit">
					Save
				</Button>
			</Form>
		</>
	);
}

const changeName = gql(`
mutation updateName($firstName: String!, $lastName: String!) {
	updateName(firstName: $firstName, lastName: $lastName) {
		firstName
		lastName
	}
}`);
const mutation = mutationAction(changeName, {
	revalidates: ['loggedInUser'],
});
